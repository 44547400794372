import './subscriptionstates.scss';
import { MdCheckCircle, MdSentimentSatisfied } from "react-icons/md";
import {useState, useEffect} from 'react';
import {requestSubscription} from "../../../../api/userRequests.js";
import appleStore from '../../../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googleStore from '../../../../assets/images/google-play-badge.svg';
import macOSButton from '../../../../assets/images/Download_for_MacOS_button.svg';
import windowsButton from '../../../../assets/images/Download_for_Windows_button.svg';

function NoSubscription(user) {

    const [ctaButtonText, setCtaButtonText] = useState("Start 14-day free trial*");
    const [smallInfoText, setSmallInfoText] = useState("*After your 14-day trial ends, you will be charged for the monthly subscription you chose. Cancel the subscription before the trial ends, so you will not be charged.");
    const [promotionText, setPromotionText] = useState("");
    const [bannerTitle, setBannerTitle] = useState("Start your musical journey with your Freedrum Studio subscription.");

    function handleSubscription() {
        requestSubscription();
    }

    return <> 
        <div className="SubscriptionScreen-mainContent__Banner">
            <div className="SubscriptionScreen-mainContent__Banner-TextBox">
                <h2 className="SubscriptionScreen-mainContent__Banner-TextBox__PromoTitle">{bannerTitle}</h2>
                <ul className="SubscriptionScreen-mainContent__Banner-TextBox__Promolist">
                    <li className="SubscriptionScreen-mainContent__Banner-TextBox__Promolist-items"><MdCheckCircle className="list-icon" size="24px" />Access the Freedrum curated courses for beginners, intermediates, and advanced level drummers <span style={{fontWeight: "600"}}>exclusive</span> to our app.</li>
                    <li className="SubscriptionScreen-mainContent__Banner-TextBox__Promolist-items"><MdCheckCircle className="list-icon" size="24px" />Get instant feedback while you play with our beautiful custom-made note highway.</li>
                    <li className="SubscriptionScreen-mainContent__Banner-TextBox__Promolist-items"><MdCheckCircle className="list-icon" size="24px" />Track your activity after each course and watch your progress improve.</li>
                </ul>
                <button className="SubscriptionScreen-mainContent__Banner-TextBox__Button" onClick={handleSubscription}>{ctaButtonText}</button>
            </div>
            <div className="SubscriptionScreen-mainContent__Banner-Image">
                
            </div>
        </div>
        <div className="SubscriptionScreen-mainContent__DownloadLink"> 
            <h2 className="SubscriptionScreen-mainContent__DownloadLink-Title">Freedrum Studio is available for Android, iOS, macOS and Windows.</h2>
            <img className="SubscriptionScreen-mainContent__DownloadLink-iOS" src={appleStore} alt="apple-app-store" onClick={()=> window.open("https://apps.apple.com/us/app/freedrum-2/id1551286191", "_blank")} />
            <img className="SubscriptionScreen-mainContent__DownloadLink-Google"src={googleStore} alt="google-store" onClick={()=> window.open("https://play.google.com/store/apps/details?id=rocks.freedrum.app&hl=en&gl=US", "_blank")} />
            <img className="SubscriptionScreen-mainContent__DownloadLink-MacOSButton"src={macOSButton} alt="macOS-download" onClick={()=> window.open("/downloads/desktop-app/Freedrum_latest.dmg", "_blank")}  />
            <img className="SubscriptionScreen-mainContent__DownloadLink-WindowsButton"src={windowsButton} alt="windows-download" onClick={()=> window.open("/downloads/desktop-app/windows/Freedrum_latest.exe", "_blank")} />
            <p className="SubscriptionScreen-mainContent__Banner-SmallTextBox">
                {smallInfoText}
            </p>
        </div>
        
    </>
}

export default NoSubscription;
