import "../../pages/auth/registerscreen/registerscreenwithinfo.scss";
import {Link} from "react-router-dom";
import React from "react";
import logoImages                                                       from "../../assets/images/Logos-images.png";


const RegisterFooterData = () => {
    return ( <div className="RegisterScreenUpdate-FooterContainer">
    <div className="RegisterScreenUpdate-FooterContainer__ContentBox">
        <div className="RegisterScreenUpdate-FooterContainer__ContentBox--Text">
            <div className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links">
            <h3 className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink">
                    <Link to={{pathname: 'mailto:hello@freedrum.rocks'}}
                        target="_blank">Contact</Link>
                </h3>
                <h3 className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink">
                    <Link to={{pathname: "https://freedrum.rocks/pages/terms-and-conditions"}}
                        target="_blank"> Terms & Conditions</Link>
                </h3>
                <h3 className="RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink">
                    <Link to={{pathname: "https://freedrum.rocks/pages/privacy-notice"}}
                        target="_blank"> Privacy Notice</Link>
                </h3>
            </div>
            <p className="RegisterScreenUpdate-FooterContainer__ContentBox--BodyText">© {(new Date().getFullYear())} Freedrum Södra Bulltoftavägen 51 B, 212 22 Malmö Sweden | hello@freedrum.rocks</p>
        </div>
         <div className="RegisterScreenUpdate-FooterContainer__ContentBox__LogoImages">
             <img className="RegisterScreenUpdate-FooterContainer__ContentBox__LogoImage-image"
                  src={logoImages} alt=""/>
         </div>
    </div>  
</div>
 );
};

const RegisterFooter = React.memo(RegisterFooterData)
export default RegisterFooter;