import "./thankyouscreen.scss"

import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

import {AiOutlineInfoCircle, AiOutlineCloseCircle, AiOutlineCheckCircle,} from "react-icons/ai";
import {doRequest} from "../../../api/requests";
import FreedrumStudioLogo from "../../../assets/images/freedrum-sequence.gif";
import ThankYouHero from "../../../assets/images/thank-you-hero.png";

import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

import FormInput from "../../../components/forminput/FormInput";
import {handleError} from "../../../api/errorHandling";
import {emailValidation, nameValidation, passwordValidation, isValid} from "../../../components/forminput/validation";

import RegisterFooter from "../../../components/register/RegisterFooter";
import ArtworkContainer from "../../../components/register/ArtworkContainer";
import DeviceListsContainer from "../../../components/register/DeviceListContainer";
import PricingContainer from "../../../components/register/PricingContainer";
import SubscriptionInfoContainer from "../../../components/register/SubscriptionInfoContainer";
import FormContainer from "../../../components/register/FormContainer";

class ThankYouScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            validation: {name: false, email: false, password: false, conditions: false},
            hasFocussedField: {name: false, email: false, password: false, conditions: false},
            accountCreated: false,
        };

        document.title = "Thank you!";
        this.registerHandler = this.registerHandler.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleTermsOfService = this.handleTermsOfService.bind(this);
    }

    componentDidMount() {
        const query = Object.fromEntries(new URLSearchParams(window.location.search).entries());
        const email = query["email"];

        if (email === undefined) {
            this.setState({
                accountExists: true,
            });
        } else {
            this.setState({
                user: {email: email},
                validation: {...this.state.validation, email: true},
                accountExists: false,
            });
        }
    }

    registerHandler(e) {
        e.preventDefault();

        this.setState({hasFocussedField: {...this.state.hasFocussedField, email: true, password: true}});

        if (!isValid(this.state.validation)) {
            if (!this.state.validation.email) {
                this.setState({errorCode: "email-address-invalid"});
            } else if (!this.state.validation.password) {
                this.setState({errorCode: "password-short"});
            } else if (!this.state.validation.conditions) {
                this.setState({errorCode: "accept-terms-missing"});
            } else {
                this.setState({errorCode: "form-missing-fields"});
            }
            return;
        }

        this.setState({errorCode: null});

        doRequest("POST", "/security/register", {email: this.state.user.email, password: this.state.user.password, name: this.state.user.name})
            .then(result => {
                ReactGA.event({
                    category: 'Account',
                    action: 'Created account',
                });
                this.setState({accountExists: true});
            })
            .catch(error => this.setState({errorCode: error}));
    };

    // User input handling - Show error when they input wrong email format, password less than 8 char, or empty name. CTA button also being disable.
    handleFormChange(input) {
        let inputName = input.name
        let inputValue = input.value;

        this.setState({errorCode: null, hasFocussedField: {...this.state.hasFocussedField, [inputName]: true}});

        if (inputName === 'name') {
            this.setState({user: {...this.state.user, [inputName]: inputValue}});
            if (nameValidation(inputValue)) {
                this.setState({validation: {...this.state.validation, [inputName]: true}});
            } else {
                this.setState({validation: {...this.state.validation, [inputName]: false}});
            }
        }

        if (inputName === 'password') {
            this.setState({user: {...this.state.user, [inputName]: inputValue}});
            if (passwordValidation(inputValue)) {
                this.setState({validation: {...this.state.validation, [inputName]: true}});
            } else {
                this.setState({validation: {...this.state.validation, [inputName]: false}});
            }
        }
    };

    handleTermsOfService(input) {
        this.setState({validation: {...this.state.validation, [input.target.name]: !this.state.validation.conditions}});
    }

    render() {
        let renderError;
        if (this.state.errorCode) {
            renderError = handleError(this.state.errorCode, null);
        }
        if (this.state.accountExists) {
            return <>
            <div className="thank-you-container">
                <div className="thank-you-image">
                    <img src={ThankYouHero} width="240px" />
                </div>

                <h1 className="thank-you-title">Thank You For Signing Up!</h1>
                <p className="thank-you-description">Download the app and sign in with {this.state.user.email} to start using your subscription.</p>
            </div>
            <div className="RegisterScreenUpdate-container">
                <DeviceListsContainer text="Download Freedrum Studio" />
                <RegisterFooter />
            </div>
            </>
        } else {
            return <>
            <div className="thank-you-container">
                <div className="thank-you-image">
                    <img src={ThankYouHero} width="240px" />
                </div>

                <h1 className="thank-you-title">Create an Account to Get Started!</h1>
            </div>
            <form className="thank-you-form" autoComplete="off" onSubmit={this.registerHandler}>
                {renderError}

                <FormInput name='name' handleFormChange={this.handleFormChange} title="Full name"
                    placeholder="Your full name" type="text"
                    valid={this.state.validation.name || !this.state.hasFocussedField.name} value={this.state.user.name}/>
                <FormInput name='email' handleFormChange={this.handleFormChange} title="Email" type="email"
                    valid={true} value={this.state.user.email} disabledStatus={true} />
                <FormInput name='password' handleFormChange={this.handleFormChange} title="Password"
                    placeholder="Password" type="password"
                    valid={this.state.validation.password || !this.state.hasFocussedField.password} value={""}/>
                <p className="thank-you-textBelowInputs password">{this.state.validation.password ?
                   <AiOutlineCheckCircle className="thank-you-wrongPasswordIcon" color="green"/> :
                   <AiOutlineCloseCircle className="thank-you-wrongPasswordIcon"/>
                }
                   Minimum 8 characters.
                </p>
                <div className="thank-you-termsAndConditions">
                   <label className="thank-you-termsAndConditions__label">
                       <input type="checkbox" name="conditions" checked={this.state.validation.conditions}
                              onChange={this.handleTermsOfService} id="terms_checkbox"/>
                       <div className="checkbox-custom"/>
                       <p htmlFor="terms_checkbox">
                           By signing up, you're agreeing to our
                           <span className="thank-you-termsAndConditions__textLink">
                               <Link to={{pathname: "https://freedrum.rocks/pages/terms-and-conditions"}}
                                     target="_blank"> Terms of Use</Link></span> and
                           <span className="thank-you-termsAndConditions__textLink">
                               <Link to={{pathname: "https://freedrum.rocks/pages/privacy-notice"}} target="_blank"> Privacy Policy</Link>
                           </span>.
                       </p>
                   </label>
               </div>
               <button type="submit" className="thank-you-registerButton" id="register-button">
                   Register
               </button>
            </form>
            <div className="RegisterScreenUpdate-container">
                <DeviceListsContainer text="Download Freedrum Studio" />
                <RegisterFooter />
            </div>

        </>
        }
    }
}

export default ThankYouScreen;