import './profilescreen.scss'
import {Link} from "react-router-dom";
import {useState, useEffect} from 'react';
import DeleteAccountModal from "./DeleteAccountModal";
import {userRequests} from "../../../../api/userRequests";
import {doAuthenticatedRequest} from "../../../../api/requests";

function ProfileScreen() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [disabledStatus, setDisabledStatus] = useState(false);
    const [fullName, setFullName] = useState("");
    const [userName, setUserName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [vid, setVid] = useState("");

    useEffect(() => {
        document.title = "Account - Profile";
        userRequests(result =>  {
            setFullName(result.name);
            setUserName(result.username);
            setEmailAddress(result.email)
        });
    }, []);

    const openModal = (status) => {
        setDisabledStatus(true)
        setIsOpen(status);
    }

    return <>
        <div className="ProfileScreen-container">
            <div className="ProfileScreen-mainContent">
                <p className="ProfileScreen-title">Profile</p>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Full name</p>
                    <p className="ProfileScreen-fieldValue">{fullName}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Username</p>
                    <p className="ProfileScreen-fieldValue">{userName}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Email</p>
                    <p className="ProfileScreen-fieldValue">{emailAddress}</p>
                </div>
                <div className="ProfileScreen-fieldContainer">
                    <p className="ProfileScreen-fieldKey">Password</p>
                    <p className="ProfileScreen-fieldValue">
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                        <span className="ProfileScreen-dot"/>
                    </p>
                </div>
                
                <Link to="/account/profile/edit">
                    <button className="ProfileScreen-goToEdit">
                        Edit profile
                    </button>
                </Link> 
                <hr />
                <p className="ProfileScreen-deleteTitle">Delete your account</p>
                <p className="ProfileScreen-deleteInfo">The account will no longer available and all data in the account
                    will be permanently deleted. This cannot be undone.</p>
                <button className="ProfileScreen-delete" onClick={() => {
                    doAuthenticatedRequest("POST", "/security/delete-account-step1", {})
                        .then((result) => {
                            setVid(result.data.vid);
                            openModal(true);
                        })
                }}>
                    Delete account
                </button>
            </div>
            <div>
                {modalIsOpen &&
                <DeleteAccountModal vid={vid} openModal={() => openModal(false)} setDisabledStatus={setDisabledStatus} email={emailAddress}/>}
            </div>
        </div>
        
    </>
}

export default ProfileScreen;
